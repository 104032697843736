<template>
    <div class="wrapper">
        <h3>巡查上报记录数量</h3>
        <dv-scroll-ranking-board :config="data" style="width:500px;height:300px" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: [
               
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.wrapper {
    width: 100%;
    height: 100%;
    padding: 10px 30px;

    h3 {
        margin: 0;
    }
}
</style>