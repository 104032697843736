<template>
    <div class="business-wrapper">
        <h2>城市加盟商营业额排名</h2>
        <dv-scroll-ranking-board :config="config" style="height:52vh; width: 100%;" />
    </div>
</template>

<script>
import { getbusinessApi } from '@/api/api'
export default {
    data() {
        return {
            config: {
                data: null,
                rowNum: 10,
                carousel: 'single',
                waitTime: 2000
            },
            businessList: null,
        }
    },
    mounted() {
        this.initgetData();
    },
    methods: {
        //初始化城市加盟商排名
        async initgetData() {
            const res = await getbusinessApi();
            this.businessList = res.data.data;

            let arr = [];
            this.businessList.forEach(val=>{
                arr.push({name:val.name,value:Number(val.value)})
            })
            this.config.data = arr;
            this.$set(this.config, 'value', arr)

        }
    },

}
</script>

<style lang="less" scoped>
.business-wrapper {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    h2 {
        font-size: 18px;
        border-left: 3px solid #58eae7;
        border-radius: 2px;
        text-indent: 1em; 
    }

    :deep(.dv-scroll-ranking-board .row-item) {
        height: 30px;
    }
}
</style>