<template>
    <div class="infomatin">
        <h2>基本信息</h2>
        <div class="slogen">
            当你征服一座山峰时，它已经在你的脚下了，你必须再找一座山峰去征服，否则，你只有下山，走下坡路了
        </div>
        <div class="info-data">
            <div class="item">
                <div class="info">
                    <div class="title">总营业额：</div>
                    <div><span>{{ infoList.jiamengtotal }}</span>万</div>
                </div>
                <div class="info">
                    <div class="title">加盟商：</div>
                    <div><span>{{ infoList.jiamengshagnnum }}</span>家</div>
                </div>
                <!-- <p class="row1">总营业额：<span>{{ infoList.jiamengtotal }}</span>万</p> -->
                <!-- <p class="row1">加盟商：<span>{{ infoList.jiamengshagnnum }}</span>家</p> -->
            </div>
            <div class="item">
                <div class="info">
                    <div class="title">技师在线人数：</div>
                    <div><span>{{ infoList.onlient }}</span>人</div>
                </div>
                <div class="info">
                    <div class="title">技师总人数：</div>
                    <div><span>{{ infoList.jishiNum }}</span>人</div>
                </div>
                <!-- <p class="row1">技师在线人数：<span>{{ infoList.onlient }}</span>人</p>
                <p class="row1">技师总人数：<span>{{ infoList.jishiNum }}</span>人</p> -->
            </div>
        </div>
    </div>
</template>

<script>
import { getinfoApi } from '@/api/api'

export default {
    data() {
        return {
            infoList: {
                jiamengtotal: 0,
                jiamengshagnnum: 0,
                onlient: 1,
                jishiNum: 1,
            }
        }
    },
    created() {
        this.initGetInfo()
    },
    methods: {
        async initGetInfo() {
            const res = await getinfoApi();

            this.infoList.jiamengtotal = res.data.data.jiamengtotal;
            this.infoList.jiamengshagnnum = res.data.data.jiamengshagnnum;
            this.infoList.onlient = res.data.data.jishi[1].num;
            this.infoList.jishiNum = res.data.data.userinfo[1].num;

        }
    }
}
</script>

<style lang="less" scoped>
.infomatin {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;

    h2 {
        font-size: 18px;
        border-left: 3px solid #58eae7;
        border-radius: 2px;
        text-indent: 1em;
    }

    .slogen {
        font-size: 14px;
        color: #58eae7;
        margin-bottom: 20px;
    }

    .info-data {
        font-size: 14px;
        width: 100%;

        .item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            margin: 10px 0;

            .info{
                width: 50%;

                .title{
                    width: 100%;
                }

                span {
                    display: inline-block;
                    font-size: 24px;
                    margin: 10px 5px 0;
                    font-weight: 700;
                    // color: #389bb7;
                    color: #00baff;
                }
            }
        }
    }
}
</style>