import axios from "@/utils/axios";

//获取商品数据
export const getMapApi = (params) => axios({
    url: '/Bdmember/newapi/cityarea',
    method: 'post',
    data: params
})


//获取在线技师人数
export const getPeopleApi = (params) => axios({
    url:'/Bdmember/newapi/onlinejishi',
    method:'post',
    data:params
})


//获取地图打点效果
export const getCircleApi = (params) =>axios({
    url:'/Bdmember/newapi/onlinelist',
    method:'post',
    data:params
})

//获取城市加盟商营业额排名
export const getbusinessApi = (params) =>axios({
    url:'/bdmember/newapi/finance',
    method:'post',
    data:params
})


//获取基本信息
export const getinfoApi = (params) =>axios({
    url:'/bdmember/newapi/statistics',
    method:'post',
    data:params
})