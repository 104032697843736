<template>
    <div class="people-wrapper">
        <h2>在线技师人数</h2>
        <dv-scroll-board :config="config" />
    </div>
</template>

<script>
import { getPeopleApi } from '@/api/api'
export default {
    data() {
        return {
            config: {
                header: ['姓名', '电话', '状态'],
                data: null,
                rowNum: 10,
                headerHeight: 30,
                align: ['center'],
                waitTime: 2000
            },
            peopleListData: null,
           
        }
    },
    created() {
        this.initPeople();


    },
    methods: {
        async initPeople() {
            const res = await getPeopleApi();
            this.peopleListData = res.data.data.data;
         
            let arr = [];
            this.peopleListData.forEach(element => {
                arr.push( [element.uname, element.mobile, !element.fu_type?'在线':'离线']);
            });
           
            this.config.data = arr;
            this.$set(this.config, 'value', arr)
            
        }
    }

}
</script>

<style lang="less" scoped>
.people-wrapper {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;

    h2 {
        font-size: 18px;
        border-left: 3px solid #58eae7;
        border-radius: 2px;
        text-indent: 1em;
    }

    :deep(.dv-scroll-board .rows .row-item) {
        height: 30px;
        line-height: 30px;
    }

    .dv-scroll-board{
        height: calc(100% - 25px);
    }
}
</style>