<template>
    <div class="map-box">
        <div id="echart_map" ref="echart_map" style="width: 100%; height:100%;"></div>

        <div class="btn">
            <button :class="{ active: active == 1 }" @mousedown="back" @mouseup="movestop" class="goback">返回上一层</button>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { getMapApi, getCircleApi } from '@/api/api'

export default {
    data() {
        return {
            myChart: null,
            dataList: null,
            adcode: null,//获取城市编码
            dataForm: null,

            CityName: null,//保存市级名称

            active: 0, //按下时候的样式

            circleList: null,//地图圆点
            circle: null,
            privicename: [], // 获取历史点击的省份名称

            option: null
        }
    },
    mounted() {

        this.initMapChart()


        this.clickGoNext();

        this.getCircle();


        window.addEventListener('resize', this.handleResize);


    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        async back() {
            this.active = 1;
            if (this.privicename.length <= 1) {
                this.initMapChart();
                this.privicename = [];
                return
            }

            
            this.CityName = this.privicename[this.privicename.length - 1];
            this.privicename.splice(this.privicename.length - 1, 1)

            const res = await getMapApi({ adcode: this.CityName });

            let mapJson = res.data.data;

            this.option = {
                grid: {
                    top: 50,
                    x: 50,
                    x2: 50,
                    y2: 80
                },
                layoutCenter: ['50%', '53%'],//位置
                layoutSize: '55%',//大小
                geo: {
                    map: 'china',
                    type: 'map',
                    roam: false,
                    zoom: 1.2,

                    //设置地图区域的样式
                    itemStyle: {
                        //设置渐变色
                        areaColor: {
                            type: 'radial',
                            x: 0.5,
                            y: 0.5,
                            r: 0.5,
                            colorStops: [{
                                offset: 0, color: '#435161' // 0% 处的颜色
                            }, {
                                offset: 1, color: '#567788' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderColor: '#74B2C0',
                        borderWidth: '2',
                        color: '#fff',
                        //设置阴影
                        shadowColor: '#567D92',
                        shadowOffsetX: -5,
                        shadowOffsetY: 5,
                        shadowBlur: 10

                    },
                    //高亮的时候地图区域
                    emphasis: {
                        itemStyle: {
                            areaColor: '#389BB7',
                            color: '#fff',
                            //设置阴影
                            shadowColor: '#6AA9C3',
                            shadowOffsetX: -5,
                            shadowOffsetY: 5,
                            shadowBlur: 10

                        },
                    },
                    label: {
                        show: false,
                        fontSize: 12,
                        color: '#fff',
                    },

                },

                //地图上打点（散点图）
                series: [
                    {
                        type: "effectScatter",
                        //坐标系
                        coordinateSystem: "geo",
                        //打点的图标样子
                        symbol: "circle",
                        legendHoverLink: true,
                        //图标的大小
                        symbolSize: [15, 15],
                        // 这里渲染标志里的内容以及样式
                        label: {
                            show: false,
                            // formatter(value) {
                            //     return value.data.value[2];
                            // },
                            color: "#fff",
                        },
                        // 标志的样式
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    console.log();
                                    return params.data.color
                                }
                            },
                        },
                        // 数据格式，其中name,value是必要的，value的前两个值是数据点的经纬度，其他的数据格式可以自定义
                        // 至于如何展示，完全是靠上面的formatter来自己定义的
                        data: this.circleList,
                        showEffectOn: "render",
                        rippleEffect: {
                            brushType: "stroke",
                        },
                        hoverAnimation: true,
                        zlevel: 1,
                    },

                ],

            };
            this.option.geo.map = this.CityName;
            echarts.registerMap(this.CityName, mapJson);
            //注册地图
            this.myChart.setOption(this.option);
        },
        movestop() {
            this.active = 0
        },
        //初始化地图
        initMapChart() {
            if (this.$refs.echart_map) {
                this.$nextTick(() => {
                    this.myChart = echarts.init(document.querySelector('#echart_map'));
                    var china = require('@/assets/map/json/china.json');

                    this.option = {
                        grid: {
                            top: 30,
                            x: 45,
                            x2: 30,
                            y2: 80
                        },
                        layoutCenter: ['50%', '70%'],//位置
                        layoutSize: '80%',//大小
                        geo: {
                            map: 'china',
                            type: 'map',
                            roam: false,
                            zoom: 1.6,

                            //设置地图区域的样式
                            itemStyle: {
                                //设置渐变色
                                areaColor: {
                                    type: 'radial',
                                    x: 0.5,
                                    y: 0.5,
                                    r: 0.5,
                                    colorStops: [{
                                        offset: 0, color: '#435161' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#567788' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                                borderColor: '#74B2C0',
                                borderWidth: '2',
                                color: '#fff',
                                //设置阴影
                                shadowColor: '#567D92',
                                shadowOffsetX: -5,
                                shadowOffsetY: 5,
                                shadowBlur: 10

                            },
                            //高亮的时候地图区域
                            emphasis: {
                                itemStyle: {
                                    areaColor: '#389BB7',
                                    color: '#fff',
                                    //设置阴影
                                    shadowColor: '#6AA9C3',
                                    shadowOffsetX: -5,
                                    shadowOffsetY: 5,
                                    shadowBlur: 10

                                },
                            },
                            label: {
                                show: false,
                                fontSize: 12,
                                color: '#fff',
                            },

                        },

                        //地图上打点（散点图）
                        series: [
                            {
                                type: "effectScatter",
                                //坐标系
                                coordinateSystem: "geo",
                                //打点的图标样子
                                symbol: "circle",
                                legendHoverLink: true,
                                //图标的大小
                                symbolSize: [15, 15],
                                // 这里渲染标志里的内容以及样式
                                label: {
                                    show: false,
                                    // formatter(value) {
                                    //     return value.data.value[2];
                                    // },
                                    color: "#fff",
                                },
                                // 标志的样式
                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            console.log();
                                            return params.data.color
                                        }

                                    },
                                },
                                // 数据格式，其中name,value是必要的，value的前两个值是数据点的经纬度，其他的数据格式可以自定义
                                // 至于如何展示，完全是靠上面的formatter来自己定义的
                                data: this.circleList,
                                showEffectOn: "render",
                                rippleEffect: {
                                    brushType: "stroke",
                                },
                                hoverAnimation: true,
                                zlevel: 1,
                            },

                        ],

                    };
                    echarts.registerMap("china", china);

                    //注册地图
                    this.myChart.setOption(this.option);
                })
            }


        },

        //点击地图
        clickGoNext() {
            this.$nextTick(() => {
                this.myChart.on('click', async (params) => {
                    if (params.name == this.CityName) {
                        return
                    }
                    if (this.privicename.indexOf(this.CityName) < 0) {
                        this.privicename.push(this.CityName);
                    }
                    this.CityName = params.name;
                  

                    const res = await getMapApi({ adcode: params.name });
                    let mapJson = res.data.data;

                    this.option = {
                        grid: {
                            top: 50,
                            x: 50,
                            x2: 50,
                            y2: 80
                        },
                        layoutCenter: ['50%', '53%'],//位置
                        layoutSize: '55%',//大小
                        geo: {
                            map: 'china',
                            type: 'map',
                            roam: false,
                            zoom: 1.2,

                            //设置地图区域的样式
                            itemStyle: {
                                //设置渐变色
                                areaColor: {
                                    type: 'radial',
                                    x: 0.5,
                                    y: 0.5,
                                    r: 0.5,
                                    colorStops: [{
                                        offset: 0, color: '#435161' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#567788' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                },
                                borderColor: '#74B2C0',
                                borderWidth: '2',
                                color: '#fff',
                                //设置阴影
                                shadowColor: '#567D92',
                                shadowOffsetX: -5,
                                shadowOffsetY: 5,
                                shadowBlur: 10

                            },
                            //高亮的时候地图区域
                            emphasis: {
                                itemStyle: {
                                    areaColor: '#389BB7',
                                    color: '#fff',
                                    //设置阴影
                                    shadowColor: '#6AA9C3',
                                    shadowOffsetX: -5,
                                    shadowOffsetY: 5,
                                    shadowBlur: 10

                                },
                            },
                            label: {
                                show: false,
                                fontSize: 12,
                                color: '#fff',
                            },

                        },

                        //地图上打点（散点图）
                        series: [
                            {
                                type: "effectScatter",
                                //坐标系
                                coordinateSystem: "geo",
                                //打点的图标样子
                                symbol: "circle",
                                legendHoverLink: true,
                                //图标的大小
                                symbolSize: [15, 15],
                                // 这里渲染标志里的内容以及样式
                                label: {
                                    show: false,
                                    // formatter(value) {
                                    //     return value.data.value[2];
                                    // },
                                    color: "#fff",
                                },
                                // 标志的样式
                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            console.log();
                                            return params.data.color
                                        }
                                    },
                                },
                                // 数据格式，其中name,value是必要的，value的前两个值是数据点的经纬度，其他的数据格式可以自定义
                                // 至于如何展示，完全是靠上面的formatter来自己定义的
                                data: this.circleList,
                                showEffectOn: "render",
                                rippleEffect: {
                                    brushType: "stroke",
                                },
                                hoverAnimation: true,
                                zlevel: 1,
                            },

                        ],

                    };
                    this.option.geo.map = params.name;
                    echarts.registerMap(params.name, mapJson);
                    //注册地图
                    this.myChart.setOption(this.option);

                })
            })
        },

        //获取地图上打点的效果
        async getCircle() {
            const res = await getCircleApi();

            this.circleList = res.data.data

            this.circleList.forEach(v => {
                v.value = v.value.split(',')
                v.value[0] = Number(v.value[0])
                v.value[1] = Number(v.value[1])
            });
            console.log(this.circleList);
            this.initMapChart();
        },

        handleResize() {
            if (this.myChart) {
                this.myChart.resize()
            }
        },

    }
}
</script>

<style lang="less" scoped>
.map-box {
    width: 100%;
    height: 100%;
}

.btn {
    position: absolute;
    left: 50px;
    bottom: 50px;
    border-radius: 50%;
}

button {
    width: 100px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    border: 0;
    color: #fff;
    background-color: rgb(51, 85, 102);
    box-shadow: 0 10px 0 #243c47;
    cursor: pointer;
}


.location {
    position: absolute;
    right: 50px;
    bottom: 50px;
    border-radius: 50%;
}

button:hover {
    background-color: #1aaf5d;
    box-shadow: 0 10px 0 #0a7237;
}

button.active {
    background-color: #11767e;
    box-shadow: 0 4px 0 #243c47;
    transform: translateY(4px);
    /* active时下移，按钮被按下的效果 */
}
</style>