<template>
    <div class="nav-wrapper">
        <div class="wrapper">
            <div class="nav-box">
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number" style="color: #F46827;">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number"  style="color: #40FAEE;">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number" style="color: #F46827;">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number"  style="color: #40FAEE;">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number" style="color: #F46827;">21793</span>公里
                        </div>
                    </div>
                </div>
                <div class="border">
                    <div class="item">
                        <h3>管养里程</h3>
                        <div>
                            <span class="number"  style="color: #40FAEE;">21793</span>公里
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <dv-decoration-10 style="width:90%;height:5px;margin: 0 5%;" />
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.nav-wrapper {
    width: 100vw;
    height: 150px;
}

.wrapper {
    width: 100vw;
    background-color: transparent;

    .nav-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // background-color: rgba(0, 0, 0, 0.1);
        background-color: #06143A;
    }

    .border {
        display: flex;
        align-items: center;
        height: 150px;
        // border: 1px solid #fff;
        // justify-content: center;
    }

    .item {
        flex: 1;
        text-align: center;

        .number {
            font-weight: 700;
            color: #4D99FC;
            font-size: 30px;
        }
    }

    .border::before {
        display: block;
        content: '';
        width: 5px;
        height: 120px;
        background: #061E5D;
    }
}
</style>